<template>
  <v-text-field
    v-bind="{
      ...$attrs,
    }"
    v-model="textInputValue"
    :suffix="`${getSuffix}`"
    v-on:keypress="(e) => manageLimit(e)"
    v-on:paste="(e) => onPaste(e)"
  ></v-text-field>
</template>
<script>
export default {
  name: "text-input-field",
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      textInputValue: null,
    };
  },

  watch: {
    value() {
      this.textInputValue = this.value;
    },
    textInputValue() {
      if (this.counter) {
        // console.log({substring:this.textInputValue})
        this.textInputValue = this.textInputValue
          ? this.textInputValue.substring(0, this.counter)
          : "";
      }
      this.$emit("input", this.textInputValue);
    },
  },
  methods: {
    manageLimit(e) {
      if (
        this.textInputValue &&
        this.textInputValue.length > this.counter - 1
      ) {
        e.preventDefault();
      }
      this.$emit("keypress", e);
    },
    onPaste(e) {
      let copied = e.clipboardData.getData("Text");
      let preVal = this.textInputValue ? this.textInputValue : "";
      let finalVal = String(preVal) + String(copied);
      if (Number(finalVal.length) > this.counter - 1) {
        let trimValue = finalVal.substring(0, this.counter);
        this.textInputValue = trimValue;
        e.preventDefault();
      }
      this.$emit("paste", e);
    },
  },
  computed: {
    counter() {
      const attr = this.$attrs;
      return attr.counter;
    },
    getSuffix() {
      const attr = this.$attrs;
      const totalLength = attr.counter;
      const typedCharLength = this.textInputValue
        ? this.textInputValue.length
        : 0;
      let suffix = "";
      if (attr.suffix) {
        suffix = `${attr.suffix} `;
      }
      if (totalLength) {
        suffix += ` ${typedCharLength}/${totalLength}`;
      }
      return suffix;
    },
  },
  mounted() {
    this.textInputValue = this.value;
  },
};
</script>
