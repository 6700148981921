import { cloneDeep, toSafeInteger, filter } from "lodash";
import ApiService from "@/core/services/api.service";
import ObjPath from "object-path";

import { SET_CUSTOMER_DATA } from "./customer.module.js";

// action types
export const SET_SCHEDULE_TYPE = "SetVisitScheduleType";
export const SET_CUSTOMER = "setVisitCustomer";
export const SET_PROPERTY = "setVisitProperty";
export const SET_PROPERTY_CONTACT = "setVisitPropertyContact";
export const SET_BILLING = "setVisitBilling";
export const SET_BILLING_CONTACT = "setVisitBillingContact";
export const GET_OPTION = "getVisitOption";
export const SET_OPTION = "setVisitOption";
export const SET_SCHEDULE = "setVisitSchedule";
export const SET_RECURRING_SCHEDULE = "setVisitRecurringSchedule";
export const SET_EDIT_RECURRING_SCHEDULE = "setVisitEditRecurringSchedule";
export const GET_AVAILABLE_TEAM = "getVisitAvailableTeam";
export const SET_AVAILABLE_TEAM = "setVisitAvailableTeam";
export const GET_LINE_ITEM = "getVisitLineItem";
export const SET_LINE_ITEM = "setVisitLineItem";
export const SET_VISIT_LINE_ITEM = "setSelectedVisitLineItem";
export const SET_VISIT_CALCULATION = "setVisitCalculation";
export const SET_VISIT_TEAM = "setVisitTeam";
export const UPDATE_VISIT_TEAM = "updateVisitTeam";
export const SET_ITEM_ALPHA = "setVisitItemAlpha";
export const SET_ITEM_CATEGORY = "setVisitItemCategory";
export const SET_VISIT_DATES = "setVisitDates";
export const SET_LOADING = "setVisitLoading";
export const RESET_STATE = "resetVisitState";
export const RESET_CREATE_STATE = "resetVisitCreateState";

export default {
  state: {
    db_schedule_type: null,
    db_schedule: {},
    db_recurring_schedule: {},
    db_edit_recurring_schedule: {},
    db_available_team: [],
    db_option: {
      ticket_types: [],
    },
    db_customer: {},
    db_property: {},
    db_property_contact: {},
    db_billing: {},
    db_calculations: {},
    db_visit_team: {},
    db_update_visit_team: {},
    db_billing_contact: {},
    db_item_categories: [],
    db_item_alphabets: [],
    db_line_items: [],
    db_selected_line_items: [],
    db_dates: [],
    local_milestone: {},
    db_loading: false,
  },
  getters: {
    vLocalMilestone() {
      let storage = localStorage.getItem("milesttone");
      return JSON.parse(storage);
    },
    vDates(state) {
      return state.db_dates;
    },
    vStateLoading(state) {
      return state.db_loading;
    },
    vSelectedLineItem(state) {
      return state.db_selected_line_items;
    },
    vCalculations(state) {
      return state.db_calculations;
    },
    vTeam(state) {
      return state.db_visit_team;
    },
    vUpdateTeam(state) {
      return state.db_update_visit_team;
    },
    vLineItemCategory(state) {
      return state.db_item_categories;
    },
    vLineItemAlpha(state) {
      return state.db_item_alphabets;
    },
    vLineItem(state) {
      return state.db_line_items;
    },
    vScheduleType(state) {
      return state.db_schedule_type;
    },
    vAvailableTeam(state) {
      return state.db_available_team;
    },
    vSchedule(state) {
      return state.db_schedule;
    },
    vRecurringSchedule(state) {
      return state.db_recurring_schedule;
    },
    vEditRecurringSchedule(state) {
      return state.db_edit_recurring_schedule;
    },
    vOption(state) {
      return state.db_option;
    },
    vCustomerId(state) {
      return state.db_customer?.id;
    },
    vPropertyId(state) {
      return state.db_property?.id;
    },
    vPropertyContactId(state) {
      return state.db_property_contact?.id;
    },
    vBillingId(state) {
      return state.db_billing?.id;
    },
    vBillingContactId(state) {
      return state.db_billing_contact?.id;
    },
    vCustomer(state) {
      return state.db_customer;
    },
    vProperty(state) {
      return state.db_property;
    },
    vPropertyContact(state) {
      return state.db_property_contact;
    },
    vBilling(state) {
      return state.db_billing;
    },
    vBillingContact(state) {
      return state.db_billing_contact;
    },
  },
  actions: {
    [RESET_STATE](context) {
      context.commit(RESET_STATE);
    },
    [RESET_CREATE_STATE](context) {
      context.commit(RESET_CREATE_STATE);
    },
    [GET_LINE_ITEM](context, payload) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LINE_ITEM, []);
        ApiService.setHeader();
        context.commit(SET_LOADING, true);
        ApiService.query("product/find-line-items", payload)
          .then((response) => {
            context.commit(
              SET_ITEM_ALPHA,
              ObjPath.get(response, "data.data.all_alpha", [])
            );
            context.commit(
              SET_ITEM_CATEGORY,
              ObjPath.get(response, "data.data.categories", [])
            );
            context.commit(
              SET_LINE_ITEM,
              ObjPath.get(response, "data.data.line_items", [])
            );
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    },
    [SET_CUSTOMER](context, payload) {
      return new Promise((resolve, reject) => {
        context.commit(SET_CUSTOMER, {});
        context.commit(SET_PROPERTY, {});
        context.commit(SET_PROPERTY_CONTACT, {});
        context.commit(SET_BILLING, {});
        context.commit(SET_BILLING_CONTACT, {});

        ApiService.setHeader();
        context.commit(SET_LOADING, true);
        ApiService.get(`customer-with-relation/${payload.id}`)
          .then((response) => {
            context.dispatch(SET_CUSTOMER_DATA, response);
            context.commit(
              SET_CUSTOMER,
              ObjPath.get(response, "data.data.customer", {})
            );
            context.commit(
              SET_PROPERTY,
              ObjPath.get(response, "data.data.property", {})
            );
            context.commit(
              SET_PROPERTY_CONTACT,
              ObjPath.get(response, "data.data.property_contact_person", {})
            );
            context.commit(
              SET_BILLING,
              ObjPath.get(response, "data.data.billing", {})
            );
            context.commit(
              SET_BILLING_CONTACT,
              ObjPath.get(response, "data.data.billing_contact_person", {})
            );
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    },
    [SET_PROPERTY](context, payload) {
      context.commit(SET_PROPERTY, payload);
    },
    [SET_PROPERTY_CONTACT](context, payload) {
      context.commit(SET_PROPERTY_CONTACT, payload);
    },
    [SET_BILLING](context, payload) {
      context.commit(SET_BILLING, payload);
    },
    [SET_BILLING_CONTACT](context, payload) {
      context.commit(SET_BILLING_CONTACT, payload);
    },
    [SET_SCHEDULE](context, payload) {
      context.commit(SET_SCHEDULE, payload);
    },
    [SET_RECURRING_SCHEDULE](context, payload) {
      context.commit(SET_RECURRING_SCHEDULE, payload);
    },
    [SET_EDIT_RECURRING_SCHEDULE](context, payload) {
      context.commit(SET_EDIT_RECURRING_SCHEDULE, payload);
    },
    [SET_SCHEDULE_TYPE](context, payload) {
      context.commit(SET_SCHEDULE_TYPE, payload);
    },
    [SET_VISIT_LINE_ITEM](context, payload) {
      context.commit(SET_VISIT_LINE_ITEM, payload);
    },
    [SET_VISIT_CALCULATION](context, payload) {
      context.commit(SET_VISIT_CALCULATION, payload);
    },
    [SET_VISIT_DATES](context, payload) {
      context.commit(SET_VISIT_DATES, payload);
    },
    [SET_VISIT_TEAM](context, payload) {
      context.commit(SET_VISIT_TEAM, payload);
    },
    [UPDATE_VISIT_TEAM](context, payload) {
      context.commit(UPDATE_VISIT_TEAM, payload);
    },
    [SET_LOADING](context, payload) {
      context.commit(SET_LOADING, payload);
    },
    [GET_OPTION](context, request) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        context.commit(SET_LOADING, true);
        ApiService.query("job/options", {
          ...request,
          customer: toSafeInteger(context?.state?.db_customer?.id),
          billing: toSafeInteger(context?.state?.db_billing?.id),
          property: toSafeInteger(context?.state?.db_property?.id),
          property_person: toSafeInteger(
            context?.state?.db_property_contact?.id
          ),
          billing_person: toSafeInteger(context?.state?.db_billing_contact?.id),
        })
          .then((response) => {
            context.commit(SET_OPTION, ObjPath.get(response, "data.data", {}));
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    },
    [GET_AVAILABLE_TEAM](context, request) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        context.commit(SET_LOADING, true);
        ApiService.query("job/available-engineers", request)
          .then((response) => {
            context.commit(
              SET_AVAILABLE_TEAM,
              ObjPath.get(response, "data.data.engineers", {})
            );
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    },
  },
  mutations: {
    [RESET_CREATE_STATE](state) {
      state.db_schedule_type = null;
      state.db_schedule = {};
      state.db_edit_recurring_schedule = {};
      state.db_recurring_schedule = {};
      state.db_available_team = [];
      state.db_option = {
        ticket_types: [],
      };
      state.db_customer = {};
      state.db_property = {};
      state.db_property_contact = {};
      state.db_billing = {};
      state.db_calculations = {};
      state.db_visit_team = {};
      state.db_update_visit_team = {};
      state.db_billing_contact = {};
      state.db_item_categories = [];
      state.db_item_alphabets = [];
      state.db_line_items = [];
      state.db_selected_line_items = [];
      state.db_dates = [];
      state.db_loading = false;
    },
    [RESET_STATE](state) {
      state.db_schedule_type = null;
      state.db_schedule = {};
      state.db_edit_recurring_schedule = {};
      state.db_recurring_schedule = {};
      state.db_available_team = [];
      state.db_option = {
        ticket_types: [],
      };
      state.db_customer = {};
      state.db_property = {};
      state.db_property_contact = {};
      state.db_billing = {};
      state.db_calculations = {};
      state.db_visit_team = {};
      state.db_update_visit_team = {};
      state.db_billing_contact = {};
      state.db_item_categories = [];
      state.db_item_alphabets = [];
      state.db_line_items = [];
      state.db_selected_line_items = [];
      state.db_dates = [];
      state.db_loading = false;
    },
    [SET_VISIT_TEAM](state, payload) {
      state.db_visit_team = cloneDeep(payload);
    },
    [UPDATE_VISIT_TEAM](state, payload) {
      state.db_update_visit_team = cloneDeep(payload);
    },
    [SET_VISIT_CALCULATION](state, payload) {
      state.db_calculations = cloneDeep(payload);
    },
    [SET_VISIT_DATES](state, payload) {
      state.db_dates = filter(payload, { status: 1 });
    },
    [SET_VISIT_LINE_ITEM](state, payload) {
      state.db_selected_line_items = cloneDeep(payload);
    },
    [SET_LOADING](state, payload) {
      state.db_loading = payload;
    },
    [SET_ITEM_ALPHA](state, payload) {
      state.db_item_alphabets = cloneDeep(payload);
    },
    [SET_ITEM_CATEGORY](state, payload) {
      state.db_item_categories = cloneDeep(payload);
    },
    [SET_LINE_ITEM](state, payload) {
      state.db_line_items = cloneDeep(payload);
    },
    [SET_SCHEDULE_TYPE](state, payload) {
      state.db_available_team = [];
      state.db_schedule_type = payload;
    },
    [SET_AVAILABLE_TEAM](state, payload) {
      state.db_available_team = cloneDeep(payload);
    },
    [SET_RECURRING_SCHEDULE](state, payload) {
      state.db_available_team = [];
      state.db_recurring_schedule = cloneDeep(payload);
    },
    [SET_EDIT_RECURRING_SCHEDULE](state, payload) {
      delete payload.ticket;
      state.db_edit_recurring_schedule = cloneDeep(payload);
    },
    [SET_SCHEDULE](state, payload) {
      state.db_available_team = [];
      state.db_schedule = cloneDeep(payload);
    },
    [SET_OPTION](state, payload) {
      state.db_option = cloneDeep(payload);
    },
    [SET_CUSTOMER](state, payload) {
      state.db_customer = cloneDeep(payload);
    },
    [SET_PROPERTY](state, payload) {
      state.db_property = cloneDeep(payload);
    },
    [SET_PROPERTY_CONTACT](state, payload) {
      state.db_property_contact = cloneDeep(payload);
    },
    [SET_BILLING](state, payload) {
      state.db_billing = cloneDeep(payload);
    },
    [SET_BILLING_CONTACT](state, payload) {
      state.db_billing_contact = cloneDeep(payload);
    },
  },
};
