import {
  isEngineer,
  currentUser,
  permissionList,
  destroyToken,
  setClientData,
  getClientData,
} from "@/core/services/jwt.service";
import {
  isEmpty,
  isObject,
  assign,
  includes,
  cloneDeep,
  findIndex,
  toString,
  isError,
} from "lodash";
import inflect from "i";
import axios from "axios";
import moment from "moment";

class GenicSetting {
  constructor() {
    this.$isEngineerChannel = false;
  }

  /*permissionDenied(el, vnode) {
    // replace HTMLElement with comment node
    const comment = document.createComment(" Permission Denied ");
    Object.defineProperty(comment, "setAttribute", {
      value: () => undefined
    });
    vnode.elm = comment;
    vnode.text = " ";
    vnode.isComment = true;
    vnode.context = undefined;
    vnode.tag = undefined;
    vnode.data.directives = undefined;

    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment;
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el);
    }
  }*/

  install(Vue) {
    const _this = this;
    Vue.mixin({
      methods: {
        limitDecimal($event, value) {
          let string = toString(value);
          // console.log($event.keyCode); //keyCodes string
          let keyCode = $event.keyCode ? $event.keyCode : $event.which;

          // only allow number and one dot
          if (
            (keyCode < 48 || keyCode > 57) &&
            (keyCode !== 46 || string.indexOf(".") != -1)
          ) {
            // 46 is dot
            $event.preventDefault();
          }

          // restrict to 2 decimal places
          if (
            string != null &&
            string.indexOf(".") > -1 &&
            string.split(".")[1].length > 1
          ) {
            $event.preventDefault();
          }
        },
        getDateCountAll(date) {
          let today = moment().format("YYYY-MM-DD");
          var a = moment(date).format("YYYY-MM-DD");
          if (moment(a).isSame(today)) {
            return true;
          }
          return false;
        },
        getDefaultRoute(route, params) {
          let route_name = "admin." + route;
          /* if (_this.$isEngineerChannel) {
            route_name = "engineer." + route;
          } */
          let result = {
            name: route_name,
          };
          if (isEmpty(params) === false && isObject(params)) {
            assign(result, params);
          }
          return result;
        },
        setChannelType() {
          isEngineer()
            .then((response) => {
              _this.$isEngineerChannel = response;
            })
            .catch((error) => {
              _this.logError(error);
            });
        },
        getWarrantyStatusColor(data) {
          if (data == 1) {
            return "cyan";
          } else if (data == 2) {
            return "green";
          } else if (data == 3) {
            return "orange";
          } else if (data == 4) {
            return "red";
          } else if (data == 5) {
            return "teal";
          }
        },
        setPageTitle() {
          let route = this.$route;
          if (isEmpty(route) === false) {
            if (isEmpty(route.meta) === false && isObject(route.meta)) {
              document.title =
                inflect().titleize(route.meta.title) + " | Genic Teams";
            }
          }
        },
        goBack() {
          this.$router.go(-1);
        },
        getCurrentUser() {
          return currentUser();
        },
        getPermissions() {
          return permissionList();
        },
        getPermission(key) {
          try {
            let permissions = permissionList();
            if (isEmpty(permissions) === false) {
              let keyArray = key.split(":");
              keyArray[1] = "full-access";
              let fullAccessKey = keyArray.join(":");
              if (includes(permissions, fullAccessKey)) {
                return true;
              }
              return includes(permissions, key);
            } else {
              destroyToken();
            }
          } catch (error) {
            _this.logError({ error });
            destroyToken();
          }
        },
        isEngineerChannel() {
          return _this.$isEngineerChannel;
        },
        checkTransactionPermission() {
          const _this = this;
          let transactions = cloneDeep(_this.createTransactions);
          for (let i = 0; i < transactions.length; i++) {
            if (
              !_this.getPermission(toString(transactions[i].action) + ":create")
            ) {
              let index = findIndex(_this.createTransactions, {
                action: transactions[i].action,
              });
              if (index >= 0) {
                _this.createTransactions.splice(index, 1);
              }
            }
          }
        },
        checkTabPermission() {
          const _this = this;
          let tabs = cloneDeep(_this.tabs);
          for (let i = 0; i < tabs.length; i++) {
            if (!_this.getPermission(toString(tabs[i].key) + ":view")) {
              let index = findIndex(_this.tabs, {
                key: tabs[i].key,
              });
              if (index >= 0) {
                _this.tabs.splice(index, 1);
              }
            }
          }
        },
        getClientDetail() {
          return new Promise((resolve, reject) => {
            let clientData = getClientData();
            if (isEmpty(clientData) === false) {
              resolve(clientData);
            } else {
              axios
                .get("https://ipgeolocation.abstractapi.com/v1", {
                  params: {
                    api_key: process.env.VUE_APP_ABSTRACTAPI_KEY,
                  },
                })
                .then(({ data }) => {
                  setClientData(data);
                  resolve(data);
                })
                .catch((error) => {
                  reject(error);
                });
            }
          });
        },
        async logError(error) {
          try {
            console.warn(error);
            /*let clientDetails = await this.getClientDetail();
            axios
              .post(
                process.env.VUE_APP_SLACK_WEBHOOK_URL,
                JSON.stringify(this.createPayload(clientDetails, error)),
                {
                  withCredentials: false,
                  transformRequest: [
                    (data, headers) => {
                      delete headers.post["Content-Type"];
                      delete headers.common["Authorization"];
                      return data;
                    }
                  ]
                }
              )
              .catch(error => {
                console.warn(error);
              });*/
          } catch (error) {
            console.warn(error);
          }
        },
        createPayload(clientDetails, clientError) {
          try {
            let route = this.$route.fullPath;
            let client = JSON.stringify(clientDetails, null, 4);
            let user = JSON.stringify(this.getCurrentUser(), null, 4);
            let error =
              typeof clientError == "string"
                ? clientError
                : JSON.stringify(clientError, null, 4);
            if (isError(clientError)) {
              error = JSON.stringify(clientError.stack, null, 4);
            }
            let errorText = "Something went wrong";
            if (typeof clientError == "string") {
              errorText = clientError.toString();
            }
            return new Object({
              attachments: [
                {
                  fallback: "You have a new Error",
                  color: "#F64E60",
                  title: process.env.VUE_APP_CLIENT_NAME,
                  title_link: process.env.VUE_APP_CLIENT_DOMAIN,
                  text: errorText,
                  fields: [
                    {
                      title: "Priority",
                      value: "High",
                      short: false,
                    },
                    {
                      title: "Client",
                      value: "```" + client + "```",
                      short: false,
                    },
                    {
                      title: "User",
                      value: "```" + user + "```",
                      short: false,
                    },
                    {
                      title: "Exception",
                      value: "```" + error + "```",
                      short: false,
                    },
                    {
                      title: "Route",
                      value:
                        "```" +
                        process.env.VUE_APP_BASE_URL +
                        route.substring(1) +
                        "```",
                      short: false,
                    },
                  ],
                  footer: "Genic Teams",
                  footer_icon:
                    process.env.VUE_APP_BASE_URL + "android-chrome-512x512.png",
                  ts: new Date().getTime(),
                },
              ],
            });
          } catch (error) {
            return new Object({
              attachments: [
                {
                  fallback: "You have a new Error",
                  color: "#F64E60",
                  title: process.env.VUE_APP_CLIENT_NAME,
                  title_link: process.env.VUE_APP_CLIENT_DOMAIN,
                  text: "*Error*: \n" + error.toString(),
                  fields: [
                    {
                      title: "Priority",
                      value: "High",
                      short: false,
                    },
                    {
                      title: "Exception",
                      value:
                        "```" + JSON.stringify(error.stack, null, 4) + "```",
                      short: false,
                    },
                    {
                      title: "Route",
                      value:
                        "```" +
                        process.env.VUE_APP_BASE_URL +
                        this.$route.fullPath.substring(1) +
                        "```",
                      short: false,
                    },
                  ],
                  footer: "Genic Teams",
                  footer_icon:
                    process.env.VUE_APP_BASE_URL + "android-chrome-512x512.png",
                  ts: new Date().getTime(),
                },
              ],
            });
          }
        },
      },
      created() {
        this.setChannelType();
        this.setPageTitle();
      },
      errorCaptured(err, vm, info) {
        this.logError({ err, vm, info });
      },
    });
  }
}

export default new GenicSetting();
